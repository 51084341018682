/* src/styles/Header.css */

.header {
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    position: relative;
    z-index: 10;
    color: #666; /* Default text color for the header */
}

.container {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap; /* Ensure that items can wrap if needed */
}

.header-title {
    font-size: 1.8em;
    font-weight: 800;
    margin-bottom: 5px;
    color: #454545; /* Default text color for header title */
}

.curated-investment-wrapper {
    display: flex;
    flex-direction: column;
    line-height: 0.2; /* Adjusted for better readability */
    margin-bottom: 10px;
}

.curated-by,
.social-links {
    margin-bottom: 10px;
    text-transform: uppercase;
}

.curated-by h2,
.social-links h2 {
    font-size: 0.8em;
    font-weight: 500;
    margin-bottom: 2px;
    color: #666; /* Default text color for subheaders */
}

.curated-by p,
.social-links p {
    font-size: 0.85em;
    font-weight: 400;
    color: #666; /* Default text color for paragraphs */
}

.curated-by a {
    font-size: 1em;
    text-decoration: none;
    margin-right: 0.5em;
    margin-bottom: 10px;
    font-weight: 600;
    text-transform: uppercase;    
    white-space: nowrap; /* Prevents links from breaking */
    color: #ff7400; /* Orange color for curator name */
}

.social-links a {
    font-size: 0.85em;
    text-decoration: none;
    color: #666; /* Default text color for social links */
    margin-right: 0.5em;
    white-space: nowrap; /* Prevents links from breaking */
}

.social-links a:last-child {
    margin-right: 0;
}

.social-links a:hover {
    text-decoration: underline;
}

/* Ensures that elements within flex containers can wrap */
.container, .curated-investment-wrapper {
    flex-wrap: wrap;
}

.contribution-links p {
    font-size: 0.85em;
    text-decoration: none;
    color: #666; /* Default text color for contribution paragraphs */
    margin-right: 0.5em;
    margin-bottom: 10px;
    text-transform: uppercase;    
    white-space: nowrap; /* Prevents links from breaking */
}

.contribution-links a {
    font-size: 1em;
    text-decoration: none;
    margin-right: 0.5em;
    margin-bottom: 10px;
    font-weight: 600;
    text-transform: uppercase;    
    white-space: nowrap; /* Prevents links from breaking */
    color: #ff7400; /* Orange color for contribution links */
}

.contribution-links a:last-child {
    margin-right: 0;
}

.contribution-links a:hover {
    text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .container {
        width: 95%; /* Increase width for smaller screens */
    }

    .header-title {
        font-size: 1.5em; /* Adjust font size for smaller screens */
    }

    .curated-by h2,
    .social-links h2,
    .curated-by p,
    .social-links p,
    .contribution-links p,
    .curated-by a,
    .social-links a,
    .contribution-links a {
        font-size: 0.75em; /* Adjust font sizes for smaller text elements */
    }
}
