/* src/styles/ArticleList.css */
.article-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
  }
  
  .article {
    background: #fff;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
  
  .title {
    font-size: 1.5em;
    color: #333;
    margin-bottom: 5px;
  }
  
  .date-author {
    color: #666;
    font-size: 0.85em;
    margin-bottom: 10px;
    text-transform: uppercase;
  }

  .fund-name {
    color: #666;
    font-size: 0.85em;
    margin-bottom: 10px;
    text-transform: uppercase;
  }  
  
  .summary {
    font-size: 1em;
    line-height: 1.6;
    color: #333;
    margin-bottom: 15px;
  }
  
  .article-image {
    width: 100%;
    max-height: 100px;
    object-fit: cover;
    border-radius: 4px;
  }
  
  /* Add responsive behavior */
  @media (max-width: 768px) {
    .article-container {
      width: 95%;
    }
  
    .title {
      font-size: 1.2em;
    }
  }
  